<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <span>Rapports > Synthèses de Charges</span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Rapport des synthèses de charges
          </div>
        </div>
      </div>
    </div>
    <div class="row my-4">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>

    <div class="row mt-4">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
    </div>
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1200"
                  filename="synthese_charge"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="700px"
                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf">
      <section slot="pdf-content">
        <div class="row justify-content-center my-4">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Rapport des synthèses de charges
              </div>
            </div>
          </div>
        </div>
        <div class="row"
             style="padding-left:90px;">
          <table class="table table-hover text-center"> 
            <thead>
              <tr class="bg-info">
                <th></th>
                <th scope="col">Rubriques</th>
                <th scope="col">Décomptes Reçus (FCFA)</th>
                <th scope="col">Décomptes payés (FCFA)</th>
                <th scope="col">Décomptes en attente de paiement (FCFA)</th>
              </tr>
            </thead>
            <tbody v-if="decompteSythese != null">
              <tr scope="row">
                <td class="bg-info align-middle" 
                    rowspan="6">SYNTHESE</td>
      
                <td>Exercice du {{ year.annee_debut }} au {{ year.annee_fin }}</td>
                <td>{{Math.round(Number(decompteSythese.annee[0].decompte_recu)).toLocaleString()}}</td>
                <td>{{Math.round(Number(decompteSythese.annee[0].decompte_paye)).toLocaleString()}}</td>
                <td>{{Math.round(Number(decompteSythese.annee[0].decompte_en_attente_payement)).toLocaleString()}}</td>
              
              </tr>
              <tr scope="row" 
                  class="table-info">
                <td>Anterieur du {{ year.annee_debut }} au {{ year.annee_fin }} </td>
                <td>{{Math.round(Number(decompteSythese.annee_n[0].decompte_recu)).toLocaleString()}}</td>
                <td>{{Math.round(Number(decompteSythese.annee_n[0].decompte_paye)).toLocaleString()}}</td>
                <td>{{Math.round(Number(decompteSythese.annee_n[0].decompte_en_attente_payement)).toLocaleString()}}</td>
              </tr>
              <tr scope="row" 
                  class="bg-dark text-light">
                <td>Total</td>
                <td>
                  {{Math.round(Number(decompteSythese.annee[0].decompte_recu+decompteSythese.annee_n[0].decompte_recu)).toLocaleString()}}
                </td>
                <td>
                  {{Math.round(Number(decompteSythese.annee[0].decompte_paye+decompteSythese.annee_n[0].decompte_paye)).toLocaleString()}}
                </td>
                <td>
                  {{Math.round(Number(decompteSythese.annee[0].decompte_en_attente_payement+decompteSythese.annee_n[0].decompte_en_attente_payement)).toLocaleString()}}
                </td>
              </tr>
              <tr scope="row" 
                  class="table-secondary">
                <td colspan="3">Décomptes retourné à l'AGEROUTE pour faute de marché</td>
                <!-- <td class="text-danger">{{Math.round(Number(decompteSythese.totaux.decomptes_retournes)).toLocaleString()}}</td> -->
                <td class="text-danger">0</td>
              </tr>
            </tbody>
            <!--LE ELSE SI AUCUNE DONNEE RECU -->
            <tbody v-else>
              <tr>
                <td>Aucune donnee trouvée</td>
              </tr>
            </tbody>
            <tfoot v-if="decompteSythese!= null">
              <tr scope="row" >
                <td class="bg-info"></td>
                <td colspan="3" 
                    class="table-secondary">Total décomptes en attente de paiement au Fer </td>
                <td>
                  {{Math.round(Number(decompteSythese.annee[0].decompte_en_attente_payement+decompteSythese.annee_n[0].decompte_en_attente_payement)).toLocaleString()}}
                </td>
              </tr>
            </tfoot>
    
            <tfoot v-else>
              <tr>
                <td> Aucune donnée trouvée</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
    </vue-html2pdf>
    <!-- END of Vue-html2pdf  -->
  
    <table class="table table-hover text-center mt-3"> 
      <thead>
        <tr class="bg-info">
          <th></th>
          <th scope="col">Rubriques</th>
          <th scope="col">Décomptes Reçus (FCFA)</th>
          <th scope="col">Décomptes payés (FCFA)</th>
          <th scope="col">Décomptes en attente de paiement (FCFA)</th>
        </tr>
      </thead>
      <tbody v-if="decompteSythese != null">
        <tr scope="row">
          <td class="bg-info align-middle" 
              rowspan="6">SYNTHESE</td>

          <td>Exercice du {{ year.annee_debut }} au {{ year.annee_fin }}</td>
          <td>{{Math.round(Number(decompteSythese.annee[0].decompte_recu)).toLocaleString()}}</td>
          <td>{{Math.round(Number(decompteSythese.annee[0].decompte_paye)).toLocaleString()}}</td>
          <td>{{Math.round(Number(decompteSythese.annee[0].decompte_en_attente_payement)).toLocaleString()}}</td>
          
        </tr>
        <tr scope="row" 
            class="table-info">
          <td>Anterieur du {{ year.annee_debut }} au {{ year.annee_fin }} </td>
          <td>{{Math.round(Number(decompteSythese.annee_n[0].decompte_recu)).toLocaleString()}}</td>
          <td>{{Math.round(Number(decompteSythese.annee_n[0].decompte_paye)).toLocaleString()}}</td>
          <td>{{Math.round(Number(decompteSythese.annee_n[0].decompte_en_attente_payement)).toLocaleString()}}</td>
        </tr>
        <tr scope="row" 
            class="bg-dark text-light">
          <td>Total</td>
          <td>
            {{Math.round(Number(decompteSythese.annee[0].decompte_recu+decompteSythese.annee_n[0].decompte_recu)).toLocaleString()}}
          </td>
          <td>
            {{Math.round(Number(decompteSythese.annee[0].decompte_paye+decompteSythese.annee_n[0].decompte_paye)).toLocaleString()}}
          </td>
          <td>
            {{Math.round(Number(decompteSythese.annee[0].decompte_en_attente_payement+decompteSythese.annee_n[0].decompte_en_attente_payement)).toLocaleString()}}
          </td>
        </tr>
        <tr scope="row" 
            class="table-secondary">
          <td colspan="3">Décomptes retourné à l'AGEROUTE pour faute de marché</td>
          <!-- <td class="text-danger">{{Math.round(Number(decompteSythese.totaux.decomptes_retournes)).toLocaleString()}}</td> -->
          <td class="text-danger">0</td>
        </tr>
      </tbody>
      <!--LE ELSE SI AUCUNE DONNEE RECU -->
      <tbody v-else>
        <tr>
          <td>Aucune donnee trouvée</td>
        </tr>
      </tbody>
      <tfoot v-if="decompteSythese!= null">
        <tr scope="row" >
          <td class="bg-info"></td>
          <td colspan="3" 
              class="table-secondary">Total décomptes en attente de paiement au Fer </td>
          <td>
            {{Math.round(Number(decompteSythese.annee[0].decompte_en_attente_payement+decompteSythese.annee_n[0].decompte_en_attente_payement)).toLocaleString()}}
          </td>
        </tr>
      </tfoot>

      <tfoot v-else>
        <tr>
          <td> Aucune donnée trouvée</td>
        </tr>
      </tfoot>
    </table>
    
  </div>
</template>
<style>
form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
form select option,
.btn-info,
form select.form-control,
span[slot="no-options"].text-danger{
  font-size: 0.6em;
}
form select.form-control option{
  font-size: 1em;
}
input.vs__search{
  font-size: 0.7em;
}
form.form_default input[type="text"], 
form.form_default input[type="date"], 
form.form_default input[type="number"], 
form.form_default input[type="file"], 
form.form_default textarea{
  height: fit-content;
}
/* div.vs__dropdown-toggle,form select.form-control{
  height: 3.5vh;
} */

@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: 2.5vh;
  }
}
/* form.form-inline input,
form.form-inline select, */
li.nav-item
/* form.form-inline button */{
  font-size: 0.6em;
}
  table.table.table-hover{
    font-size: 0.7em;
  }
</style>
<script>
import { mapActions, mapGetters } from "vuex"
import VueHtml2pdf from 'vue-html2pdf'
export default {
  name: "Synthese",
  components:{
    VueHtml2pdf,
  },
  data: () => ({
    decompteSythese: null,
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
    year:{
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
    },
  }),
  watch: {
    TableauDecompteSynthese() {
      this.decompteSythese = this.TableauDecompteSynthese.donnees[0],
      console.log(this.decompteSythese)
    },
  },
  created() {
    //this.decomptePer = this.TableauDecomptePer.donnees
    this.getTableauDecompteSynthese({annee_debut:this.year.annee_debut,annee_fin:this.year.annee_fin,charge:"SYNTHESE"})
  },
  computed: {
    ...mapGetters(["TableauDecompteSynthese"]),
  },
  methods: {
    //@vuese
    //La recuperation du tableau de données
    ...mapActions(["getTableauDecompteSynthese"]),
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    changeYear() {
      this.year.annee_debut= this.annee_debut
      this.year.annee_fin= this.annee_fin
      this.getTableauDecompteSynthese({annee_debut:this.year.annee_debut,annee_fin:this.year.annee_fin,charge:"SYNTHESE"})
    },
  },
  
}
</script>